<template>
  <fieldset class="karla lh-copy pt4 bn">
    <div>
      <p class="ma0 f6 grey">{{ $t("payment_method.method") }}</p>
      <button
        v-bind:class="{ black: isPayPal, 'silver dim': !isPayPal }"
        @click="onPaymentMethod('PAYPAL')"
        class="f6 pa2 mh2 mv1 ba border-box"
      >
        <font-awesome-icon :icon="['fab', 'cc-paypal']" /> PayPal
      </button>

      <button
        v-bind:class="{ black: isBankTransfer, 'silver dim': !isBankTransfer }"
        @click="onPaymentMethod('BANK_TRANSFER')"
        class="f6 pa2 mh1 mv2 ba border-box"
      >
        <font-awesome-icon :icon="['fas', 'university']" />
        {{ $t("payment_method.bank_transfer") }}
      </button>
    </div>
    <div>
      <input
        class="mr2"
        type="checkbox"
        id="payment-terms"
        v-model="isTermsAccepted"
      />
      <label for="terms" class="lh-copy">
        {{ $t("product.terms1") }}
        <router-link
          :to="$i18nRoute({ name: 'terms' })"
          class="piiia-grey hover-piiia-turqoise link"
          >{{ $t("product.terms2") }}</router-link
        >
        {{ $t("product.terms3") }}
      </label>
    </div>
    <div>
      <input
        class="mr2"
        type="checkbox"
        v-model="user.is_mail_list_subscription"
      />
      <label for="mail" class="lh-copy">{{ $t("product.newsletter") }}</label>
    </div>

    <!-- PayPalButton requires document... -->
    <no-ssr>
      <div class="mt2" v-on:click="onPaypalButton">
        <paypal-button
          v-bind:class="{ ben: !isTermsAccepted, dn: !isPayPal }"
        />
      </div>
    </no-ssr>

    <div v-bind:class="{ dn: !isBankTransfer }">
      <button
        style="min-width: 20rem;"
        @click="onBankTransfer()"
        class="w-90 bg-gold roboto f6 black dim br2 b pa2 pv3"
      >
        <font-awesome-icon :icon="['fas', 'university']" />
        {{ $t("payment_method.bank_transfer") }}
      </button>
      <span class="db">{{ $t("payment_method.pay") }}</span>
    </div>
    <span
      v-if="!isTermsAccepted && (isPayPalButtonClick || isBankTransferClick)"
      class="db karla dark-red f5"
      >* {{ $t("error.terms") }}</span
    >
  </fieldset>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import PayPalButton from "./PayPalButton.vue";
import NoSSR from "vue-no-ssr";

export default {
  name: "product-purchase",
  data() {
    return {
      isTermsAccepted: false,
      isPayPalButtonClick: false,
      isBankTransferClick: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters("cart", {
      isPayPal: "isPayPal",
      isBankTransfer: "isBankTransfer"
    }),
    ...mapGetters("cart", {
      isPayPal: "isPayPal",
      isBankTransfer: "isBankTransfer"
    })
  },
  methods: {
    onPaypalButton() {
      this.isPayPalButtonClick = true;
    },
    onPaymentMethod(paymentMethod) {
      this.$store.commit("cart/setPaymentMethod", paymentMethod);
    },
    onBankTransfer() {
      this.isBankTransferClick = true;
      if (this.isTermsAccepted) {
        this.$router.push({ name: "order/save" });
      }
    }
  },
  created() {},
  mounted() {
    if (this.isDisabled) {
      this.$store.commit("cart/setPaymentMethod", "PAYPAL");
    }
  },
  props: {},
  components: {
    "paypal-button": PayPalButton,
    "no-ssr": NoSSR
  }
};
</script>
