<template>
  <div>
    <!-- Loading -->
    <div v-if="loading">
      <button
        class="f6 dim ph3 pv2 mb2 pb0 dib white bg-black lh-copy roboto sans-serif ttu tracked tc b"
      >
        <font-awesome-icon fas fa :icon="currencySign" />
        <van-loading class="ml2 dib" />
      </button>
    </div>
    <!-- ShowPopUp 'BuyNow' -->
    <div v-if="isPurchasable === true">
      <button
        @click="showPopup"
        class="f6 dim ph3 pv2 mb2 pb0 dib white bg-black lh-copy roboto sans-serif ttu tracked tc b"
      >
        <font-awesome-icon fas fa :icon="currencySign" />
        {{ $t("product.buy_now") }}
      </button>
      <van-popup :safeAreaInsetBottom="true" class="tc" v-model="show">
        <button
          v-if="country"
          @click="onShowGlobalView"
          class="f7 btn-link db black bg-animate hover-bg-black dim pa2 mh2 mv2 ba border-box"
        >
          {{ $t("product.country") + " " + country.country }}
        </button>
        <product-price :product="product" />
        <cart-total />
        <product-purchase />
      </van-popup>
    </div>
    <!-- Hide -->
    <div class="dark-red" v-else-if="isPurchasable === false && country">
      {{ $t("product.not_purchasable", { country: country.country }) }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import CartTotal from "./carttotal/CartTotal.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductPurhcase from "./ProductPurchase.vue";

export default {
  name: "product-description",
  computed: {
    ...mapGetters("user", {
      currencySign: "currencySign",
      residentCode: "residentCode",
      currency: "currency"
    }),
    ...mapGetters("cart", {
      productIds: "productIds"
    }),

    language() {
      return this.$route.params.lang;
    }
  },
  methods: {
    showPopup() {
      // remove all previous
      this.$store.commit("cart/removeAll");
      this.$store.commit("pricing/remove");
      // add new one
      this.$store.commit("cart/add", { product: this.product, quantity: 1 });
      this.$store.dispatch("pricing/calculate", {
        cart: this.productIds,
        language: this.language
      });
      this.show = true;
    },
    onShowGlobalView() {
      this.$store.commit("user/setIsGlobalView", true);
    }
  },
  data() {
    return {
      show: false
    };
  },
  props: {
    product: Object,
    country: Object,
    isPurchasable: Boolean,
    loading: Boolean,
    prices: Object
  },
  created() {},
  components: {
    "cart-total": CartTotal,
    "product-price": ProductPrice,
    "product-purchase": ProductPurhcase
  },
  watch: {
    currency(newValue, oldValue) {
      this.$store.dispatch("pricing/calculate", {
        cart: this.productIds,
        language: this.language
      });
    },
    residentCode(newValue, oldValue) {
      this.$store.dispatch("pricing/calculate", {
        cart: this.productIds,
        language: this.language
      });
    }
  }
};
</script>
