<template>
  <paypal-button
    :env="env"
    :commit="commit"
    :button-style.camel="style"
    :client="client"
    :payment="payment"
    :on-cancel.camel="onCancel"
    :on-error.camel="onError"
    :on-authorize.camel="onAuthorize"
  />
</template>

<script>
// ReadMore:
// https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
// https://developer.paypal.com/docs/archive/checkout/how-to/customize-button/#button-styles
// https://github.com/paypal/paypal-checkout-components/blob/master/demo/card.htm

import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "paypal-checkout-button-item",

  computed: {
    ...mapGetters("paypal", {
      error: "error",
      loading: "loading"
    })
  },
  data() {
    return {
      commit: true,
      env: process.env.VUE_APP_PAYPAL_MODE,
      style: {
        size: "large",
        color: "gold",
        shape: "rect",
        label: "checkout",
        tagline: "true"
      },
      client: {
        sandbox:
          "Ad5DG--82C4eeiKOOGbSqSLNY93C8m-0gcKpk5CWDYyYvhW155QM86aV9roHy2JZEGUHdKCl5hWBOkMU",
        production:
          "AfIu2-AcLgZr8cpQu_knL5BFCV5uizZb8bkiKrBU5M_pTwzmNstcHGi0qoecdfZJI_FNhtDAG7GrKOvC"
      }
    };
  },
  methods: {
    payment(data, actions) {
      this.isPayPalButtonClick = false;
      return this.$store.dispatch("paypal/onPayment", {
        button: actions.payment
      }); //NOTE: DO NOT CHANGE ANYTHING!
    },
    onAuthorize(data, actions) {
      this.isPayPalButtonClick = false;
      return this.$store.dispatch("paypal/onAuthorize", {
        button: actions.payment,
        router: this.$router
      }); //NOTE: DO NOT CHANGE ANYTHING!
    },
    onCancel(data) {
      this.isPayPalButtonClick = false;
      this.$store.dispatch("paypal/onCancel", { cancel: data }); //NOTE: DO NOT CHANGE ANYTHING!
    },
    onError(err) {
      this.isPayPalButtonClick = false;
      this.$store.dispatch("paypal/onError", {
        error: err,
        router: this.$router
      }); //NOTE: DO NOT CHANGE ANYTHING!
    }
  },
  mounted() {
    this.$store.dispatch("paypal/created");
  },
  components: {}
};
</script>
