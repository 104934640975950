<template>
  <!-- v-bind:class="styles"  -->

  <button
    class="f6 dim ph3 pv2 mb2 pb0 dib white bg-black lh-copy roboto sans-serif ttu tracked tc b"
    v-show="isVisible"
    :disabled="isDisabled"
    v-on:click.prevent="onOpenClick()"
  >
    <font-awesome-icon :icon="['far', 'list-alt']" />
    {{ $t("shopping_list.open") }}
  </button>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "open-list",
  props: {
    type: String,
    productId: String,
    sku: String
  },
  data() {
    return {};
  },
  methods: {
    onOpenClick() {
      if (!this.authenticated) {
        return;
      }
      this.$router.push({ name: "shopping_list", params: { sku: this.sku } });
    },
    fetchIsPurchased() {
      if (!this.authenticated) {
        return;
      }
      this.$store.dispatch("purchase/isPurchased", {
        productId: this.productId,
        cookie: this.$cookie
      });
    }
  },
  computed: {
    ...mapState({
      isPurchased: state => state.purchase.isPurchased,
      authenticated: state => state.auth.isAuthenticated
    }),
    isDisabled() {
      return !this.authenticated || !this.isPurchased[this.productId];
    },
    isVisible() {
      return this.type.toLowerCase() === "shopping_list";
    }
  },
  created() {},
  mounted() {
    this.fetchIsPurchased();
  },
  components: {}
};
</script>
