<template>
  <div class="ph5-l ph4" v-if="product">
    <div class="tc pb4">
      <h1 class="f2 pt4 ttu vidaloka mb2-l mb2 pt0">{{ product.name }}</h1>
      <span class="f5 roboto sans-serif i piiia-grey">{{
        $t("product.type_" + product.type.toLowerCase())
      }}</span>
    </div>

    <div class="w-100">
      <div class="flex justify-center">
        <buy-now
          :product="product"
          :loading="false"
          :country="country"
          :isPurchasable="isPurchasable"
          class="mr2"
        />

        <!-- OpenList -->
        <open-list
          :sku="this.product.sku"
          :type="this.product.type"
          :productId="this.product.id"
          class="ml2"
        />
      </div>
    </div>

    <article class="pv2 karla tracked-light lh-copy">
      <div v-html="product.long_description"></div>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import OpenList from "./OpenList.vue";
import BuyNow from "./ProductBuyNow.vue";
import ProductPurhcase from "./ProductPurchase.vue";

export default {
  name: "product-description",
  computed: {},
  methods: {},
  props: {
    product: Object,
    country: Object,
    isPurchasable: Boolean
  },
  created() {},
  components: {
    "open-list": OpenList,
    "buy-now": BuyNow,
    "product-purchase": ProductPurhcase
  }
};
</script>
