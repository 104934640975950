<template>
  <div class="w-100 f5 normal karla lh-copy">
    <div v-if="prices">
      <shipment />
      <!--<subtotal />-->
      <total />
    </div>
    <div v-if="error" class="dark-red">{{ error }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import Shipment from "./Shipment.vue";
import Subtotal from "./Subtotal.vue";
import Total from "./Total.vue";

export default {
  name: "cart-total",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState({
      error: state => state.pricing.error,
      loading: state => state.pricing.loading
    }),
    ...mapGetters("user", {
      currency: "currency",
      residentCode: "residentCode"
    }),
    ...mapGetters("pricing", {
      prices: "prices"
    }),
    ...mapGetters("cart", {
      cart: "cart"
    })
  },
  mounted() {},
  watch: {
    currency(newValue, oldValue) {},
    residentCode(newValue, oldValue) {}
  },
  components: {
    Shipment,
    Subtotal,
    Total
  }
};
</script>
