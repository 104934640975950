<template>
  <div v-if="product">
    <h3 class="normal karla f4 lh-copy mt0">
      {{ product.name }}
      <span class="db normal f6 karla lh-copy">
        <i>{{ $t("product.type_" + product.type.toLowerCase()) }}</i>
      </span>
    </h3>

    <div v-if="product.is_discount === 'true'">
      <span class="f5 b karla">
        <span class="red"
          >{{ discount()
          }}<font-awesome-icon size="xs" fas fa :icon="currencySign"
        /></span>
        <span class="strike f6">
          {{ price() }}<font-awesome-icon size="xs" fas fa :icon="currencySign"
        /></span>
        <van-stepper
          v-if="isSticker"
          @change="onChange"
          v-model="value"
          min="1"
          max="5"
        />
      </span>
    </div>
    <div v-else>
      <span class="f5 b karla"
        >{{ price() }}<font-awesome-icon size="xs" fas fa :icon="currencySign"
      /></span>
      <van-stepper
        v-if="isSticker"
        @change="onChange"
        v-model="value"
        min="1"
        max="5"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "product-price",
  computed: {
    ...mapGetters("user", {
      currencySign: "currencySign"
    }),
    sku() {
      return this.$route.params.sku;
    },
    ...mapGetters("cart", {
      productIds: "productIds"
    }),
    ...mapGetters("pricing", {
      prices: "prices"
    }),
    language() {
      return this.$route.params.lang;
    },
    isSticker() {
      return this.product.type.toLowerCase() == "sticker";
    }
  },
  data() {
    return {
      value: 1
    };
  },
  methods: {
    onChange(value) {
      this.$store.commit("cart/increment", { sku: this.sku, quantity: value });
      this.$store.dispatch("pricing/calculate", {
        cart: this.productIds,
        language: this.language
      });
    },
    price() {
      if (this.prices == null) {
        return "...";
      }
      const product = this.prices.products[0];
      if (product == null) {
        return "...";
      }
      return product.price_with_vat;
    },
    discount() {
      if (this.prices == null) {
        return "...";
      }
      const product = this.prices.products[0];
      if (product == null) {
        return "...";
      }
      return product.discount_with_vat;
    }
  },
  props: {
    product: Object
  },
  created() {},
  components: {}
};
</script>
