<template>
  <div v-if="prices" class="fl w-100 f3">
    <!-- <div class="fl w-50">-->
    <span class="">{{ $t("cart.total_amount") }} </span>
    <!-- </div> -->
    <!-- <div class="fl w-50"> -->

    <span class="b f2"
      >{{ prices.amount.total
      }}<font-awesome-icon size="xs" fas fa :icon="currencySign"
    /></span>

    <!-- </div> -->
    <span class="f7 db i karla"
      >{{
        $t("cart.vat", {
          vat: prices.tax_rate.standard_vat,
          taxes_with_shipping: prices.amount.total_taxes,
          currency: prices.currency
        })
      }}
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "total",
  data() {
    return {};
  },
  methods: {
    fetchTaxRates() {}
  },
  computed: {
    ...mapState({
      error: state => state.pricing.error,
      loading: state => state.pricing.loading
    }),
    ...mapGetters("pricing", {
      prices: "prices"
    }),
    ...mapGetters("user", {
      currencySign: "currencySign"
    })
  },
  created() {},
  mounted() {},
  watch: {},
  components: {}
};
</script>
