<template>
  <article class="w-50-l w-100 fl">
    <div v-if="error" class="dark-red">{{ error }}</div>
    <van-image class="w-100" fit="contain" :src="image">
      <template #loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
  </article>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "product-images",
  computed: {
    image() {
      if (this.images == null || this.imageIds.length == 0) {
        return null;
      }
      return this.images[this.imageIds[0]].src;
    },
    ...mapGetters("image", {
      images: "images"
    })
  },
  props: {
    imageIds: Array,
    error: [Error, Object],
    loading: Boolean
  },
  methods: {},
  created() {},
  components: {}
};
</script>
