<template>
  <div v-if="prices" class="fl w-100 pb1">
    <div v-if="prices.shipment.with_vat > 0">
      <!-- <div class="fl w-50"> -->
      <span class="">{{ $t("cart.shipping_cost") }} </span>
      <!-- </div> -->
      <!-- <div class="fl w-50"> -->
      <span class=" b"
        >{{ prices.shipment.with_vat
        }}<font-awesome-icon size="xs" fas fa :icon="currencySign"
      /></span>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "shipment",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState({
      error: state => state.pricing.error,
      loading: state => state.pricing.loading
    }),
    ...mapGetters("shipment", {
      shipmentCostId: "shipmentCostId"
    }),
    ...mapGetters("pricing", {
      prices: "prices"
    }),
    ...mapGetters("user", {
      currencySign: "currencySign"
    })
  },
  created() {},
  mounted() {},
  watch: {},
  components: {}
};
</script>
