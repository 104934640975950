<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <div class="cf">
      <product-images v-bind:imageIds="imageIds" />
      <div v-if="error" class="dark-red">{{ error }}</div>
      <article v-else class="w-50-l w-100 fl bg-white">
        <product-description
          :product="product"
          :isPurchasable="isPurchasable"
          :country="country"
        />
      </article>
    </div>
  </section>
</template>

<script>
import ProductImages from "../components/ProductImages.vue";
import ProductDescription from "../components/ProductDescription.vue";

import { mapGetters, mapState } from "vuex";
import updateTdk from "../utils/tdk";

export default {
  name: "product-view",
  components: {
    "product-images": ProductImages,
    "product-description": ProductDescription
  },
  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    //console.log(this.loading)
    // return the Promise from the action
    // so that the component waits before rendering
    return this.fetchProduct();
  },
  methods: {
    name() {
      if (!this.product) {
        return "PIIIAshop";
      }
      return this.product.name;
    },
    shortDescription() {
      if (!this.product) {
        return "";
      }
      return this.product.short_description;
    },
    fetchLocalization() {
      //		console.log("fetchLocalization..")
      return this.$store.dispatch("user/localization", {
        cookie: this.$cookie,
        router: this.$router
      });
    },
    fetchProduct() {
      return this.$store
        .dispatch("product/product", {
          language: this.language,
          sku: this.sku
        })
        .finally(() => {
          updateTdk.call(this);
        });
    },
    loaded() {
      //console.log("loaded...")
      this.$store.dispatch("country/countries", { language: this.language }); // fetch countries for the buy-now.view
      this.$store.dispatch("image/loadImages", {
        pageSize: 1,
        sku: this.sku,
        pageToken: 0
      });
      //this.$store.dispatch("image/loadImages", { pageSize: 1, sku: this.sku, pageToken: 0 }).then(() => {
      //	console.log(this.imageIdsBySku(this.sku))
      //});
      if (!this.isPurchasable) {
        //	console.log("not purchasable...")
        //if (isInitial) {
        //	this.$store.commit("cart/add", { product: this.product, quantity: 1  })
        //}
        //console.log("calculate price")
      } else {
        //console.log("is purchasable...")
        // show error
        // remove from cart
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      residentCode: "residentCode",
      currency: "currency"
    }),
    ...mapGetters("cart", {
      cart: "cart"
    }),
    ...mapGetters("country", {
      country: "country"
    }),
    ...mapGetters("cart", {
      cart: "cart"
    }),
    ...mapGetters("product", {
      products: "products",
      products2: "products2",
      error: "error",
      isPurchasable: "isPurchasable"
    }),
    ...mapGetters("image", {
      imageIdsBySku: "imageIdsBySku"
    }),
    ...mapGetters("pricing", {
      isPriceLoading: "loading"
    }),
    sku() {
      return this.$route.params.sku;
    },
    product() {
      return this.products[this.key];
    },
    imageIds() {
      return this.imageIdsBySku(this.sku);
    },
    language() {
      return this.$route.params.lang;
    },
    key() {
      return this.sku;
    }
  },
  tdk() {
    return {
      title: this.name(),
      description: this.shortDescription(),
      keywords:
        "pakuprojekti, piiia, pakun rakentaminen, reissupakun rakentaminen, vanlife suomi, van life suomi, vanlife finland, van life finland, shop our van, shop our plumbing, shop our solar, vesijärjestelmä, matkailuajoneuvo talvella, aurinkopaneeli autoon, aurinkosähkö, aurinkosähköjärjestelmä, sähkökartta, koti pakussa, liikkuva koti, asuntoauton rakentaminen, matkailuauton rakentaminen, paku projekti, pakun rakennus, matkailuauto, asuntoauto, retkeilyauto, reissupaku, retkipaku, retkipakun rakentaminen, retkeilyauton rakentaminen",
      ssrHeadAddInfo: "",
      language: this.language
    };
  },
  mounted() {
    this.fetchLocalization().then(() => {
      this.fetchProduct().then(() => {
        this.loaded();
      });
    });
  },
  destroyed() {},
  watch: {
    currency(newValue, oldValue) {
      //	this.$store.commit("product/deleteBySku", this.sku)
      this.fetchProduct().then(() => {
        this.loaded();
      });
    },
    residentCode(newValue, oldValue) {
      //	this.$store.commit("product/deleteBySku", this.sku)
      this.fetchProduct().then(() => {
        this.loaded();
      });
    },
    language(newValue, oldValue) {
      this.$store.commit("product/delete", this.products2);
      this.fetchProduct().then(() => {
        this.loaded();
      });
    }
  }
};
</script>
