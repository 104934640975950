<template>
  <div v-if="prices" class="fl w-100">
    <!-- <div class="fl w-50"> -->
    <span>SubTotal:</span>
    <!-- </div> -->
    <!-- <div class="fl w-50"> -->
    <span class=" b" v-if="prices.amount.sub_total_with_shipment > 0">
      {{ prices.amount.sub_total_with_shipment }} {{ prices.currency }}
    </span>
    <span class=" b" v-else>
      {{ prices.amount.sub_total_without_shipment }} {{ prices.currency }}
    </span>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "subtotal",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState({
      error: state => state.pricing.error,
      loading: state => state.pricing.loading
    }),
    ...mapGetters("pricing", {
      prices: "prices"
    })
  },
  created() {},
  mounted() {},
  watch: {},
  components: {}
};
</script>
